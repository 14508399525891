<template>
    <div :key="updateResult">
      <!-- Discussion Thread Modal -->
          <b-modal
            v-model="showDiscussionThreadModal"
            scrollable
            :title="cvDiscussionThreadHeader"
            size="xl"
            id="xxl"
          >
            <DiscussionThread :propTopicObj="propTopicObj"/>
            <template #modal-footer="">
              <b-button size="sm" @click="closeDiscussionThread()">
                {{cvbtnModalCancel}}
              </b-button>
            </template>
          </b-modal><!-- Discussion Thread Modal -->
      <div class="iq-card" id="ansExpCont" v-if="questionList.length > 0">
      <div class="iq-card-header d-flex justify-content-between">
        <div class="iq-header-title pb-3">
          <div class="pt-4">
            <span v-for="(app, index) of quizReport.chained_apps" :key="index">
              <b-badge
                style="cursor:pointer; color: #e5252c; background-color: white"
                class="border mr-2 mb-2 font-weight-normal"
                variant="none"
                @click="gotoNextAppAnalysis(app.app_id)">
                <span>{{ app.app_name }}</span>
              </b-badge>
            </span>
          </div>
          <h4>
            Showing Analysis For:&nbsp;
            {{quizReport.app_name}}
          </h4>
          <h5 v-if="$route.query.attempt" class="heading mb-1">Student Name: <span class="primary-color pointer" @click="goToUserProfile(userDetails.user_id)">{{ userDetails.user_name }}</span></h5>
          <table v-if="$route.query.attempt">
            <tr><td>Total Questions:  {{quizReport.total_questions}}</td></tr>
            <tr><td>Attempted Questions:  {{quizReport.attempted_questions}}</td></tr>
            <tr><td class="success-color">Correct Answers:  {{quizReport.correct_answers}}</td></tr>
            <tr><td class="warning-color">Wrong Answers:  {{quizReport.wrong_answers}}</td></tr>
          </table>
        </div>
      </div>
      </div>
      <div v-else>
        No Data Found
      </div>
      <div class="sidebarContainer">
        <div v-for="(ques, index) in questionList" :key="index" class="iq-card p-3">
          <h4 class="title questionTag" style="font-size:15px" v-if="ques?.question">
           <span>Q{{index+1}})&nbsp;{{ques.qbk_id}}</span>
           <p v-html="ques.question"></p>
          </h4>
          <img class="que_img" v-if="ques?.image" style="" :src="ques.image" />
          <div class="col-md-12 col-lg-12 col-sm-12 questionBox pl-4 pr-4">
            <div class="optionContainer">
              <template v-if="ques?.is_mcq == 1">
                <div
                  class="option"
                  v-for="(choice, option) in ques.answer_choices"
                  :key="option"
                  style="display:block"
                >
                <p style="display: inline-flex;"><span :class="{option_tag: true , bg_green: ques.correct_answers == ques.user_response && ques.user_response == option && $route.query.attempt, bg_red: ques.correct_answers != ques.user_response && ques.user_response == option && $route.query.attempt }">{{ option }}</span> <span v-html="choice" style="display:inline;"></span></p>
                </div>
                  <p class="success-color"><span style="font-weight:600">Correct answer:</span>
                  <span class="text-center p-2" style="text-transform: uppercase;"> {{ques.correct_answers}}</span>
                </p>
                <p><span style="font-weight:600">Explanation: </span>
                  <span v-if="ques.answer_explanation" v-html="ques.answer_explanation"></span>
                  <small v-else> Not Available</small>
                </p>

                <p><span style="font-weight:600">Answer Category: </span>
                  <span v-if="ques.answer_category"> {{getAnswerCategory(ques)}}</span>
                  <small v-else> Not Available</small>
                </p>
                <span @click="openDiscussionThread(ques)" class="pointer" style="color:#e5252c">Do you have any doubt ?</span>
              </template>
              <template v-else>
                <p>
                  <span style="font-weight:600">Your answer:</span>
                  <span class="text-center p-2" style="text-transform: uppercase;"> {{ques.user_response}}</span>
                </p>
                <p><span style="font-weight:600">Explanation: </span>
                  <span v-if="ques.answer_explanation" v-html="ques.answer_explanation"></span>
                  <small v-else> Not Available</small>
                </p>

                <p><span style="font-weight:600">Answer Category: </span>
                  <span v-if="ques.answer_category"> {{getAnswerCategory(ques)}}</span>
                  <small v-else> Not Available</small>
                </p>
                <span @click="openDiscussionThread(ques)" class="pointer" style="color:#e5252c">Do you have any doubt ?</span>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center" v-if="isLoading">
         <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
    </div>
</template>

<style lang="scss" scoped>
  .que_img{
    min-width: 220px;
    width: 100%;
    max-width: max-content;
    margin-bottom: 25px;
  }
  h4 {
    padding: 12px 13px 18px;
    padding-left: 0;
    & svg {
      width: 25px;
      height: 21px;
      position: relative;
      top: 3px;
      margin-right: 6px;
      vertical-align: baseline;
    }
  }
  .option_tag{
    text-transform: capitalize;
    font-weight: 600;
    margin-right: 10px;
    border: 1px solid var(--iq-body-text);
    padding: 1px 8px;
    border-radius: 30px;
    color: var(--iq-body-text);
    height: 30px;
    width: 29px;
    position: relative;
    top: -3px;
   }

   .bg_green {
    background-color: rgb(0, 128, 0, 0.5);
    border: 1px solid rgba(3, 68, 3, 0.5);
    color: #fff;
   }
   .bg_red {
    background-color: rgb(245, 53, 53);
    border: 1px solid rgb(204, 46, 46);
    color: #fff;
   }
</style>

<script>
import { socialvue } from "../config/pluginInit.js"
import { QuizAnswers } from "../FackApi/api/quizAnswers.js"
import { User } from "../FackApi/api/user.js"
import { Apps } from "../FackApi/api/apps"
import DiscussionThread from "./DiscussionThread.vue"
import { base64decode } from "nodejs-base64"
import "katex/dist/katex.min.css"
import katex from "katex"

export default {
  name: "AppAnswerExplanation",

  data () {
    return {
      questionList: [],
      isLoading: false,
      appId: null,
      userId: null,
      userDetails: {},
      appDetails: {},
      quizReport: {},
      offset: 1,
      dontCallAPI: false,
      showDiscussionThreadModal: false,
      cvDiscussionThreadHeader: "Discussion Thread",
      cvbtnModalCancel: "Cancel",
      updateResult: 0,
      propTopicObj: null
    }
  },
  components: {
    DiscussionThread
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    socialvue.index()
    this.appId = this.$route.params.appId
    this.userId = this.$route.params.userId

    window.addEventListener("scroll", () => {
      this.isLoading = true
      if ((window.innerHeight + Math.ceil(document.documentElement.scrollTop)) >= document.documentElement.scrollHeight) {
        this.offset++
        this.getAppAnswerExplanation()
        this.isLoading = false
      }
    })

    this.getUserDetails()
    this.getAppDetails()
    this.getQuizReportList()

    this.getAppAnswerExplanation()
  },
  methods: {
    /**
     * getAppAnswerExplanation
     */
    async getAppAnswerExplanation (calledForNewApp = false) {
      const payload = {
        app_id: this.appId,
        userId: this.userId
      }

      if (this.$route.query.attempt) {
        payload.attempt = this.$route.query.attempt
      }

      if (!this.dontCallAPI) {
        const appAnswerExplanationResp = await QuizAnswers.quizAnswersExplanationList(this, payload, this.offset)
        if (appAnswerExplanationResp.resp_status) {
          if (!calledForNewApp) {
            this.questionList.push(...appAnswerExplanationResp.resp_data.data)
          }
          else {
            this.questionList = appAnswerExplanationResp.resp_data.data
          }

          var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
          for (let i in this.questionList) {
            if (this.questionList[i].answer_explanation) {
              this.questionList[i].answer_explanation = base64regex.test(this.questionList[i].answer_explanation) ? base64decode(this.questionList[i].answer_explanation) : this.questionList[i].answer_explanation
              // this.questionList[i].answer_explanation = this.questionList[i].answer_explanation.replace(/\n\n/g, "<br/><br/>").replace(/\\/g, "")
              /*
              * For Future Use to detect LaTex Script. ~ => Space, //=> Newline.
              * Currently can't enter multiple new lines as Katex does not recognise it
              * IS_LATEX_RESPONSE needs to be added in the GPT Response
              */
              let answerExplanationLatex = ""
              if (this.questionList[i].answer_explanation.includes("IS_LATEX_RESPONSE")) {
                this.questionList[i].answer_explanation = this.questionList[i].answer_explanation.replace("IS_LATEX_RESPONSE", "")
                answerExplanationLatex = this.questionList[i].answer_explanation.replace(/ /g, "~").replace(/\\\[/g, "").replace(/\\\]/g, "").replace(/\\\(/g, "").replace(/\\\)/g, "").replace(/\n/g, "\\")
                answerExplanationLatex = katex.renderToString(answerExplanationLatex, { throwOnError: false })
                this.questionList[i].answer_explanation = answerExplanationLatex
              }

              this.questionList[i].answer_explanation = this.questionList[i].answer_explanation.replace("IS_LATEX_RESPONSE", "")
              this.questionList[i].answer_explanation = this.questionList[i].answer_explanation.replace(/\n/g, "<br/>").replace(/\\/g, "")
            }
          }
        }
        else {
          this.dontCallAPI = true
        }
      }
    },
    /**
     * getAnswerCategory
     */
    getAnswerCategory (ques) {
      let answerCategory = ""
      try {
        answerCategory = JSON.parse(ques.answer_category)[ques.user_response]
      }
      catch (e) {
        console.error("Exception is JSON Parsing answer_category", e)
      }
      if (answerCategory) {
        return answerCategory
      }
      else {
        return "Not available"
      }
    },
    /**
     * getUserDetails
     */
    async getUserDetails () {
      const user = await User.userView(this, this.userId, this.userData.user_id)
      if (user.resp_status) {
        this.userDetails = user.resp_data.data
      }
    },
    /**
     * getAppDetails
     */
    async getAppDetails () {
      const app = await Apps.appView(this, this.appId)
      if (app.resp_status) {
        this.appDetails = app.resp_data.data
      }
    },
    /**
     * goToUserProfile
     */
    async goToUserProfile (userId) {
      this.$router.push(`/user/${userId}`)
    },
    /**
     * getQuizReportList
     */
    async getQuizReportList () {
      const payload = {
        app_id: this.appId,
        other_user_id: this.userId
      }
      if (this.$route.query.attempt) {
        payload.attempt = this.$route.query.attempt
      }
      const quizReportListResp = await QuizAnswers.quizReportList(this, payload)
      if (quizReportListResp.resp_status) {
        this.quizReport = quizReportListResp.resp_data.data[0]
      }
    },
    /**
     * closeDiscussionThread
     */
    closeDiscussionThread () {
      this.showDiscussionThreadModal = false
    },
    /**
     * openDiscussionThread
     */
    openDiscussionThread (questionObj) {
      // creating html
      const htmlContent = `
        <div class="iq-card p-3">
          <h4 class="title questionTag" style="font-size:15px" v-if="questionObj?.question">
            <span>QBK_ID:&nbsp;${questionObj.qbk_id}</span>
            <p>${questionObj.question}</p>
          </h4>
          ${(questionObj.image) ? `<img class="que_img" style="" src="${questionObj.image}" />` : ""}
          <div class="col-md-12 col-lg-12 col-sm-12 questionBox pl-4 pr-4">
            <div class="optionContainer">
              ${this.buildOptionsForPassingHTML(questionObj.answer_choices, questionObj.correct_answers)}
            </div>

            <p><span style="font-weight:600">Explanation: </span>
              <span v-if="questionObj.answer_explanation"> ${questionObj.answer_explanation}</span>
              <small v-else> Not Available</small>
            </p>

            <p><span style="font-weight:600">Answer Category: </span>
              ${(questionObj.answer_category) ? `<span> ${this.getAnswerCategory(questionObj)}</span>` : `<small> Not Available</small>`}
            </p>
          </div>
      `
      this.propTopicObj = { module_name: "test_prep", module_obj_id: questionObj.qbk_id, content_type: "html", content: htmlContent }
      this.showDiscussionThreadModal = true
    },
    /**
     * buildOptionsForPassingHTML
     */
    buildOptionsForPassingHTML (choices, correctAnswer) {
      let loopedHtml = ""
      for (let optionNo in choices) {
        loopedHtml += `<div
          class="option"
          style="display:block"
        >
          <p style="display: inline-flex;"><span class="option_tag ${correctAnswer == optionNo ? "bg_green" : ""}">${optionNo.toUpperCase()}</span> <span style="display:inline;">${choices[optionNo]}</span></p>
        </div>
      `
      }
      return loopedHtml
    },
    /**
     * gotoNextAppAnalysis
     */
    gotoNextAppAnalysis (appId) {
      let path = `/analyse_app_result/${appId}/${this.userId}?attempt=${this.$route.query.attempt}`

      if (this.$route.path != path) {
        this.$router.push(path)
      }
    }
  },
  watch: {
    "$route.path": function () {
      this.appId = this.$route.params.appId
      this.offset = 1
      this.dontCallAPI = false
      this.getAppAnswerExplanation(true)
      this.getQuizReportList()
      this.updateResult = Math.random()
    }
  }
}
</script>
