import { render, staticRenderFns } from "./DiscussionThread.vue?vue&type=template&id=512ce68c"
import script from "./DiscussionThread.vue?vue&type=script&lang=js"
export * from "./DiscussionThread.vue?vue&type=script&lang=js"
import style0 from "./DiscussionThread.vue?vue&type=style&index=0&id=512ce68c&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports